<template>
  <AuthLayout>
    <template #form>
      <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Recuperar senha</h2>
      <h6 class="subtitle-1">Preencha seu email cadastrado e siga os passos enviados para seu email.</h6>
      <v-form ref="form" v-model="valid">
        <v-text-field v-model="form.email" :rules="emailRules" label="E-mail" name="email" dense class="mt-4" required
          outlined :loading="isLoading" :disabled="isLoading"></v-text-field>
        <v-btn :disabled="!valid || isLoading" color="info" block class="mr-4" submit outlined :loading="isLoading"
          @click="submit">Enviar</v-btn>
      </v-form>
      <div class="mt-5 text-center">
        <a @click="$router.push('/login')">
          <small>
            Voltar
          </small>
        </a>
      </div>
    </template>
  </AuthLayout>
</template>

<script>
import collect from 'collect.js'

export default {
  data: () => ({
    valid: true,
    show1: false,
    form: {
      email: ''
    },
    emailRules: [
      v => !!v || 'O campo e-mail é obrigatório',
      v => /.+@.+/.test(v) || 'O e-mail precisa ser válido'
    ],
    checkbox: false,
    isLoading: false
  }),
  methods: {
    handleErrors (errors) {
      collect(errors).each(error => {
        this.$store.dispatch('alert', { msg: error[0], color: 'red' })
      })
    },
    submit () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.isLoading = true
      this.$axios.post('auth/password/forgot', { email: this.form.email })
        .then(response => {
          this.$store.dispatch('alert', { msg: response.data.message })
          this.$router.push('/login')
        })
        .catch(error => {
          if (error.response.data) {
            this.handleErrors(error.response.data.errors)
          }
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>
